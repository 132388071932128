<template>
  <ApprovalCard
    :request="registration"
    :is-approving="isApproving"
    :is-rejecting="isRejecting"
    @approve="onApprove"
    @reject="onReject"
  >
    <template #form>
      <VComment
        v-model="comments"
        class="col-span-2"
        :label="$t('app.instructor_comment')"
      />
    </template>
  </ApprovalCard>
</template>

<script>
import { ref, computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Components
import ApprovalCard from "@/views/teaching/approvals/ApprovalCard";
import VComment from "@/components/inputs/VComment";

export default {
  components: {
    ApprovalCard,
    VComment
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["refresh"],
  setup(props, context) {
    // Composables
    const { isRequesting: isApproving, request: approve } = useRequest();
    const { isRequesting: isRejecting, request: reject } = useRequest();
    const { getText } = useTexts();

    // Data
    const comments = ref(props.request.comments);

    // Computed
    const registration = computed(() => {
      return {
        ...props.request,
        registration: {
          ...props.request
        }
      };
    });

    // Methods
    const onApprove = async () => {
      await approve({
        endpoint: "teaching.registrations.approval",
        pathParams: { id: props.request.id },
        data: {
          completion_approver_comments: comments.value,
          decision: "Approved"
        }
      });

      context.emit("refresh");
    };

    const onReject = async () => {
      await reject({
        endpoint: "teaching.registrations.approval",
        pathParams: { id: props.request.id },
        data: {
          completion_approver_comments: comments.value,
          decision: "Rejected"
        }
      });

      context.emit("refresh");
    };

    return {
      registration,
      comments,
      onApprove,
      onReject,
      // useTexts
      getText,
      // useRequest
      isApproving,
      isRejecting
    };
  }
};
</script>
