<template>
  <RegistrationCard :request="request">
    <template #content>
      <slot name="content" />
    </template>

    <template #form>
      <slot name="form" />
    </template>

    <template v-if="canApprove" #actions>
      <div class="flex justify-end">
        <VButton
          :label="$t('app.reject')"
          :is-loading="isRejecting"
          icon="fal fa-times-circle"
          class="btn-danger btn-sm mr-2"
          @click="$emit('reject')"
        />

        <VButton
          :label="$t('app.approve')"
          :is-loading="isApproving"
          icon="fal fa-check-circle"
          class="btn-success btn-sm"
          @click="$emit('approve')"
        />
      </div>
    </template>
  </RegistrationCard>
</template>

<script>
import { computed } from "vue";
// Components
import RegistrationCard from "@/views/teaching/RegistrationCard";
import VButton from "@/components/VButton";

export default {
  components: {
    RegistrationCard,
    VButton
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    },
    isApproving: {
      type: Boolean,
      default: false
    },
    isRejecting: {
      type: Boolean,
      default: false
    }
  },
  emits: ["approve", "reject"],
  setup(props) {
    const canApprove = computed(() => {
      const hasCanApprove = "can_approve" in props.request;
      return !hasCanApprove || (hasCanApprove && props.request.can_approve);
    });

    return {
      canApprove
    };
  }
};
</script>
