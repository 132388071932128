<template>
  <VCard actions-class="bg-theme-5 rounded-md">
    <template v-if="!disableComments" #content>
      <VComment v-model="comment" :label="$t('app.instructor_comment')" />
    </template>

    <template #actions>
      <div class="flex justify-end">
        <VButton
          :label="$t('app.reject_all')"
          :is-loading="isRejecting"
          icon="fal fa-times-circle"
          class="btn-danger btn-sm mr-2"
          @click="$emit('rejectAll', comment)"
        />

        <VButton
          :label="$t('app.approve_all')"
          :is-loading="isApproving"
          icon="fal fa-check-circle"
          class="btn-success btn-sm"
          @click="$emit('approveAll', comment)"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref } from "vue";
// Components
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import VComment from "@/components/inputs/VComment";

export default {
  components: {
    VCard,
    VComment,
    VButton
  },
  props: {
    isApproving: {
      type: Boolean,
      default: false
    },
    isRejecting: {
      type: Boolean,
      default: false
    },
    disableComments: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    // Data
    const comment = ref("");

    return {
      comment
    };
  }
};
</script>
