<template>
  <div>
    <VTitle class="flex justify-baseline items-center route-title">
      {{ $t("app.registration_trainings", 2) }}

      <VHint class="ml-4 text-base">
        {{ $t("hints.teaching_approvals_registration_trainings") }}
      </VHint>
    </VTitle>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert
      v-else-if="data.length === 0"
      :text="$t('app.no_pending_approvals')"
    />

    <div v-else class="grid gap-8">
      <ApprovalAll
        :is-rejecting="isRejecting"
        :is-approving="isApproving"
        @rejectAll="onRejectAll"
        @approveAll="onApproveAll"
      />

      <Registration
        v-for="request in data"
        :key="request.id"
        :request="request"
        @refresh="getData"
      />
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useRequest from "@/composables/useRequest";
// Components
import Registration from "./Registration";
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import ApprovalAll from "@/views/teaching/approvals/ApprovalAll";
import VHint from "@/components/VHint";

export default {
  components: {
    Registration,
    VHint,
    VTitle,
    VAlert,
    ApprovalAll
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { data, isLoading, read } = useReadMultiple();

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.registration_trainings", 2)} - ${t("app.approvals")} - ${t("app.teaching")}`;
    const { isRequesting: isApproving, request: approve } = useRequest();
    const { isRequesting: isRejecting, request: reject } = useRequest();

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.registrations.approvals"
      });
    };

    const onApproveAll = async comment => {
      const promises = data.value.map(request => {
        approve({
          endpoint: "teaching.registrations.approval",
          pathParams: { id: request.id },
          data: {
            instructor_comments: comment,
            decision: "Approved"
          }
        });
      });

      await Promise.all(promises);

      await getData();
    };

    const onRejectAll = async comment => {
      const promises = data.value.map(request => {
        reject({
          endpoint: "teaching.registrations.approval",
          pathParams: { id: request.id },
          data: {
            instructor_comments: comment,
            decision: "Rejected"
          }
        });
      });

      await Promise.all(promises);

      await getData();
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      onApproveAll,
      onRejectAll,
      documentTitle,
      // useRequest
      isApproving,
      isRejecting,
      // useReadMultiple
      data,
      isLoading,
      getData
    };
  }
};
</script>
