<template>
  <div>
    <VLine class="form-label" :label="label" />

    <VWysiwyg
      :model-value="modelValue"
      class="comment"
      :readonly="readonly"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script>
// Mixins
import InputMixin from "@/mixins/InputMixin";
// Components
import VLine from "@/components/VLine";
import VWysiwyg from "@/components/inputs/VWysiwyg";

export default {
  components: {
    VWysiwyg,
    VLine
  },
  mixins: [InputMixin],
  emits: ["blur"]
};
</script>

<style scoped lang="scss">
.comment :deep(.input-group .ck-editor) {
  @apply shadow-none;

  & .ck-editor__top .ck-sticky-panel .ck-sticky-panel__content .ck-toolbar {
    @apply border-none;

    & .ck-toolbar__items {
      @apply hidden;
    }
  }

  & .ck-editor__main .ck-content {
    min-height: 52px;
    @apply rounded-lg shadow-none;

    & p {
      @apply my-1;
    }
  }
}
</style>
